import './style.scss'
import { GiHamburgerMenu } from 'react-icons/gi'
import { AiOutlineClose } from 'react-icons/ai'
import { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
// import EraahLogo from '../../Assets/eraahLogo.png'
import arrowRight from '../../Assets/arrow-right.png'
// import EraahLogo from '../../Assets/Eraah-official-logo.png'

function CustomNavbar() {
    const [opened, setOpened] = useState(false);

    const handleOpen = () => setOpened(true);
    const handleClose = () => setOpened(false);
    const location = useLocation();

    const [color, setColor] = useState('');


    function scrollToSection(sectionId) {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    }
    // const Close = () => setClick(false);
    function scrollToUpward(color) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    // const Close = () => setClick(false);
    // function scrollToUpward(color){
    //     if(color==='Home'){
    //         window.scrollTo({ top: 0, behavior: 'smooth' });
    //         setColor('Home')
    //     }
    //     else if(color==='About us'){
    //         window.scrollTo({ top: 0, behavior: 'smooth' });
    //         setColor('About us')
    //     }
    //     if(color==='Team'){
    //         window.scrollTo({ top: 0, behavior: 'smooth' });
    //         setColor('Team')
    //     }
    //     if(color==='Book A Free consultant'){
    //         window.scrollTo({ top: 0, behavior: 'smooth' });
    //         setColor('Book A Free consultant')
    //     }
    // }

    function handleToggle() {

        handleClose();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <div className="navbar-area mb-3">
            <div className="container-fluid d-flex flex-row justify-content-between align-items-center" style={{justifyContent: 'center'}} >
                <NavLink to={'/'} className="navbar-brand" >
                </NavLink>
                <div className="navItems">
                    <li ><NavLink className={`links ${location.pathname === '/' ? 'active' : ''}`} to={'/'} onClick={() => scrollToUpward('Home')} >Home</NavLink></li>

                    <li><NavLink className={`links ${location.pathname === '/works' ? 'active' : ''}`} to={'/works'} onClick={() => scrollToUpward('Works')}>How It Works</NavLink></li>

                    <li><NavLink className={`links ${location.pathname === '/blog' ? 'active' : ''}`} to={'/blog'} onClick={() => scrollToUpward('Blog')}>Blog</NavLink></li>

                    
                    <li> <NavLink className={`links ${location.pathname === '/logIn' ? 'active' : ''}`} to={'/logIn'} onClick={() => scrollToUpward('logIn')}>Log in</NavLink></li>

                    {/* <li><a className={`links ${location.pathname === '/logIn' ? 'active' : ''}`} href='https://msite.eraah.org/' target="_blank" onClick={scrollToUpward('')}>Log in</a></li> */}
                </div>

                <div className="navItems2">
                <li> <NavLink className={`links ${location.pathname === '/contactus' ? 'active' : ''}`} to={'/contactus'} onClick={() => scrollToUpward('Contact')}> <button type="button" className="btn" style={{backgroundColor: '#6100FF'}}>Book A Call <img src={arrowRight} alt="ArrowIcon" width={'20%'} style={{ marginTop: '-3px' }} /></button></NavLink></li>
                </div>

                <div className="nav-toggler-icon" onClick={handleOpen}>
                    <GiHamburgerMenu className='hamIcon' />
                </div>
                <div id='customSidebar' className={opened ? "custom-sidebar show" : "custom-sidebar"}>
                    <div className="close-btn-wrapper">
                        <AiOutlineClose className='close-icon' onClick={handleClose} />
                    </div>
                    <ul className='sidebar-list'>
                        <li><NavLink to={'/'} onClick={handleToggle}>Home</NavLink></li>
                        <li><NavLink to={'/works'} onClick={handleToggle}>How It Works</NavLink></li>
                        <li><NavLink to={'/blog'} onClick={handleToggle}>Blog</NavLink></li>

                        {/* <li><NavLink to={""}>Expertise</NavLink></li>
                <li><NavLink to={""}>Investment  Strategy</NavLink></li> */}

                        
                        {/* <li><NavLink to={'/donate'} onClick={handleToggle}><button type="button" class="btn">Donate</button></NavLink></li> */}
                        <li><NavLink to={'/logIn'} onClick={handleToggle}>Log In</NavLink></li>

                        <li><NavLink to={'/contactus'} onClick={handleToggle}>Book A Call</NavLink></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default CustomNavbar

// // import './style.scss';
// // import { GiHamburgerMenu } from 'react-icons/gi';
// // import { AiOutlineClose } from 'react-icons/ai';
// // import { useState } from 'react';
// // import { NavLink, useLocation } from 'react-router-dom';
// // import arrowRight from '../../Assets/arrow-right.png';

// // function CustomNavbar() {
// //     const [opened, setOpened] = useState(false);

// //     const handleOpen = () => setOpened(true);
// //     const handleClose = () => setOpened(false);
// //     const location = useLocation();

// //     const [color, setColor] = useState('');

// //     function scrollToUpward(color) {
// //         window.scrollTo({ top: 0, behavior: 'smooth' });
// //     }

// //     function handleToggle() {
// //         handleClose();
// //         window.scrollTo({ top: 0, behavior: 'smooth' });
// //     }

// //     return (
// //         <div className="navbar-area mb-5">
// //             <div className="container-fluid d-flex justify-content-between align-items-center">
// //                 {/* Logo Section */}
// //                 <NavLink to={'/'} className="navbar-brand d-flex align-items-center">
// //                     <img
// //                         src="/path-to-logo.png"
// //                         alt="Eraah Logo"
// //                         style={{ height: '40px' }} // Adjust the height as needed
// //                     />
// //                     <span className="ms-2 fw-bold" style={{ fontSize: '20px' }}>Eraah</span>
// //                 </NavLink>

// //                 {/* Navigation Links */}
// //                 <ul className="nav d-flex align-items-center mb-0">
// //                     <li className="nav-item">
// //                         <NavLink
// //                             className={`links ${location.pathname === '/' ? 'active' : ''} px-3`}
// //                             to={'/'}
// //                             onClick={() => scrollToUpward('Home')}
// //                         >
// //                             Home
// //                         </NavLink>
// //                     </li>
// //                     <li className="nav-item">
// //                         <NavLink
// //                             className={`links ${location.pathname === '/works' ? 'active' : ''} px-3`}
// //                             to={'/works'}
// //                             onClick={() => scrollToUpward('Works')}
// //                         >
// //                             How It Works
// //                         </NavLink>
// //                     </li>
// //                     <li className="nav-item">
// //                         <NavLink
// //                             className={`links ${location.pathname === '/blog' ? 'active' : ''} px-3`}
// //                             to={'/blog'}
// //                             onClick={() => scrollToUpward('Blog')}
// //                         >
// //                             Blog
// //                         </NavLink>
// //                     </li>
// //                     <li className="nav-item">
// //                         <NavLink
// //                             className={`links ${location.pathname === '/logIn' ? 'active' : ''} px-3`}
// //                             to={'/logIn'}
// //                             onClick={() => scrollToUpward('logIn')}
// //                         >
// //                             Log in
// //                         </NavLink>
// //                     </li>
// //                 </ul>

// //                 {/* Button Section */}
// //                 <NavLink to={'/contactus'} onClick={() => scrollToUpward('Donate')}>
// //                     <button type="button" className="btn btn-primary d-flex align-items-center">
// //                         Book A Call
// //                         <img
// //                             src={arrowRight}
// //                             alt="Arrow Icon"
// //                             style={{ width: '16px', marginLeft: '8px' }}
// //                         />
// //                     </button>
// //                 </NavLink>

// //                 {/* Hamburger Menu */}
// //                 <div className="nav-toggler-icon" onClick={handleOpen}>
// //                     <GiHamburgerMenu className="hamIcon" />
// //                 </div>
// //                 <div
// //                     id="customSidebar"
// //                     className={opened ? 'custom-sidebar show' : 'custom-sidebar'}
// //                 >
// //                     <div className="close-btn-wrapper">
// //                         <AiOutlineClose className="close-icon" onClick={handleClose} />
// //                     </div>
// //                     <ul className="sidebar-list">
// //                         <li>
// //                             <NavLink to={'/'} onClick={handleToggle}>
// //                                 Home
// //                             </NavLink>
// //                         </li>
// //                         <li>
// //                             <NavLink to={'/works'} onClick={handleToggle}>
// //                                 How It Works
// //                             </NavLink>
// //                         </li>
// //                         <li>
// //                             <NavLink to={'/blog'} onClick={handleToggle}>
// //                                 Blog
// //                             </NavLink>
// //                         </li>
// //                         <li>
// //                             <NavLink to={'/contactus'} onClick={handleToggle}>
// //                                 Book A Call
// //                             </NavLink>
// //                         </li>
// //                         <li>
// //                             <NavLink to={'/logIn'} onClick={handleToggle}>
// //                                 Log In
// //                             </NavLink>
// //                         </li>
// //                     </ul>
// //                 </div>
// //             </div>
// //         </div>
// //     );
// // }

// // export default CustomNavbar;


// // import './style.scss';
// // import { GiHamburgerMenu } from 'react-icons/gi';
// // import { AiOutlineClose } from 'react-icons/ai';
// // import { useState } from 'react';
// // import { NavLink, useLocation } from 'react-router-dom';
// // import arrowRight from '../../Assets/arrow-right.png';

// // function CustomNavbar() {
// //     const [opened, setOpened] = useState(false);

// //     const handleOpen = () => setOpened(true);
// //     const handleClose = () => setOpened(false);
// //     const location = useLocation();

// //     const [color, setColor] = useState('');

// //     function scrollToUpward(color) {
// //         window.scrollTo({ top: 0, behavior: 'smooth' });
// //     }

// //     function handleToggle() {
// //         handleClose();
// //         window.scrollTo({ top: 0, behavior: 'smooth' });
// //     }

// //     return (
// //         <div className="navbar-area mb-5">
// //             <div className="container-fluid d-flex justify-content-between align-items-center">
// //                 {/* Logo Section */}
// //                 <NavLink to={'/'} className="navbar-brand d-flex align-items-center">
// //                     <img
// //                         src="/path-to-logo.png"
// //                         alt="Eraah Logo"
// //                         style={{ height: '40px' }} // Adjust the height as needed
// //                     />
// //                     <span className="ms-2 fw-bold" style={{ fontSize: '20px' }}>Eraah</span>
// //                 </NavLink>

// //                 {/* Navigation Links */}
// //                 <ul className="nav d-flex align-items-center mb-0">
// //                     <li className="nav-item">
// //                         <NavLink
// //                             className={`links ${location.pathname === '/' ? 'active' : ''} px-3`}
// //                             to={'/'}
// //                             onClick={() => scrollToUpward('Home')}
// //                         >
// //                             Home
// //                         </NavLink>
// //                     </li>
// //                     <li className="nav-item">
// //                         <NavLink
// //                             className={`links ${location.pathname === '/works' ? 'active' : ''} px-3`}
// //                             to={'/works'}
// //                             onClick={() => scrollToUpward('Works')}
// //                         >
// //                             How It Works
// //                         </NavLink>
// //                     </li>
// //                     <li className="nav-item">
// //                         <NavLink
// //                             className={`links ${location.pathname === '/blog' ? 'active' : ''} px-3`}
// //                             to={'/blog'}
// //                             onClick={() => scrollToUpward('Blog')}
// //                         >
// //                             Blog
// //                         </NavLink>
// //                     </li>
// //                     <li className="nav-item">
// //                         <NavLink
// //                             className={`links ${location.pathname === '/logIn' ? 'active' : ''} px-3`}
// //                             to={'/logIn'}
// //                             onClick={() => scrollToUpward('logIn')}
// //                         >
// //                             Log in
// //                         </NavLink>
// //                     </li>
// //                 </ul>

// //                 {/* Button Section */}
// //                 <NavLink to={'/contactus'} onClick={() => scrollToUpward('Donate')}>
// //                     <button type="button" className="btn btn-primary d-flex align-items-center">
// //                         Book A Call
// //                         <img
// //                             src={arrowRight}
// //                             alt="Arrow Icon"
// //                             style={{ width: '16px', marginLeft: '8px' }}
// //                         />
// //                     </button>
// //                 </NavLink>

// //                 {/* Hamburger Menu */}
// //                 <div className="nav-toggler-icon" onClick={handleOpen}>
// //                     <GiHamburgerMenu className="hamIcon" />
// //                 </div>
// //                 <div
// //                     id="customSidebar"
// //                     className={opened ? 'custom-sidebar show' : 'custom-sidebar'}
// //                 >
// //                     <div className="close-btn-wrapper">
// //                         <AiOutlineClose className="close-icon" onClick={handleClose} />
// //                     </div>
// //                     <ul className="sidebar-list">
// //                         <li>
// //                             <NavLink to={'/'} onClick={handleToggle}>
// //                                 Home
// //                             </NavLink>
// //                         </li>
// //                         <li>
// //                             <NavLink to={'/works'} onClick={handleToggle}>
// //                                 How It Works
// //                             </NavLink>
// //                         </li>
// //                         <li>
// //                             <NavLink to={'/blog'} onClick={handleToggle}>
// //                                 Blog
// //                             </NavLink>
// //                         </li>
// //                         <li>
// //                             <NavLink to={'/contactus'} onClick={handleToggle}>
// //                                 Book A Call
// //                             </NavLink>
// //                         </li>
// //                         <li>
// //                             <NavLink to={'/logIn'} onClick={handleToggle}>
// //                                 Log In
// //                             </NavLink>
// //                         </li>
// //                     </ul>
// //                 </div>
// //             </div>
// //         </div>
// //     );
// // }

// // export default CustomNavbar;


// import './style.scss';
// import { GiHamburgerMenu } from 'react-icons/gi';
// import { AiOutlineClose } from 'react-icons/ai';
// import { useState } from 'react';
// import { NavLink, useLocation } from 'react-router-dom';
// import arrowRight from '../../Assets/arrow-right.png';

// function CustomNavbar() {
//     const [opened, setOpened] = useState(false);

//     const handleOpen = () => setOpened(true);
//     const handleClose = () => setOpened(false);
//     const location = useLocation();

//     const [color, setColor] = useState('');

//     function scrollToUpward(color) {
//         window.scrollTo({ top: 0, behavior: 'smooth' });
//     }

//     function handleToggle() {
//         handleClose();
//         window.scrollTo({ top: 0, behavior: 'smooth' });
//     }

//     return (
//         <div className="navbar-area mb-5">
//             <div className="container-fluid d-flex justify-content-between align-items-center">
//                 {/* Logo Section */}
//                 <NavLink to={'/'} className="navbar-brand d-flex align-items-center">
//                     <img
//                         src="/path-to-logo.png"
//                         alt="Eraah Logo"
//                         style={{ height: '40px' }} // Adjust the height as needed
//                     />
//                     <span className="ms-2 fw-bold" style={{ fontSize: '20px' }}>Eraah</span>
//                 </NavLink>

//                 {/* Navigation Links */}
//                 <ul className={`nav d-flex align-items-center mb-0 ${opened ? 'show-nav' : ''}`}>
//                     <li className="nav-item">
//                         <NavLink
//                             className={`links ${location.pathname === '/' ? 'active' : ''} px-3`}
//                             to={'/'}
//                             onClick={() => scrollToUpward('Home')}
//                         >
//                             Home
//                         </NavLink>
//                     </li>
//                     <li className="nav-item">
//                         <NavLink
//                             className={`links ${location.pathname === '/works' ? 'active' : ''} px-3`}
//                             to={'/works'}
//                             onClick={() => scrollToUpward('Works')}
//                         >
//                             How It Works
//                         </NavLink>
//                     </li>
//                     <li className="nav-item">
//                         <NavLink
//                             className={`links ${location.pathname === '/blog' ? 'active' : ''} px-3`}
//                             to={'/blog'}
//                             onClick={() => scrollToUpward('Blog')}
//                         >
//                             Blog
//                         </NavLink>
//                     </li>
//                     <li className="nav-item">
//                         <NavLink
//                             className={`links ${location.pathname === '/logIn' ? 'active' : ''} px-3`}
//                             to={'/logIn'}
//                             onClick={() => scrollToUpward('logIn')}
//                         >
//                             Log in
//                         </NavLink>
//                     </li>
//                 </ul>

//                 {/* Button Section */}
//                 <NavLink to={'/contactus'} onClick={() => scrollToUpward('Donate')}>
//                     <button type="button" className="btn btn-primary d-flex align-items-center">
//                         Book A Call
//                         <img
//                             src={arrowRight}
//                             alt="Arrow Icon"
//                             style={{ width: '16px', marginLeft: '8px' }}
//                         />
//                     </button>
//                 </NavLink>

//                 {/* Hamburger Menu */}
//                 <div className="nav-toggler-icon" onClick={handleOpen}>
//                     <GiHamburgerMenu className="hamIcon" />
//                 </div>
//                 <div
//                     id="customSidebar"
//                     className={`custom-sidebar ${opened ? 'show' : ''}`}
//                 >
//                     <div className="close-btn-wrapper">
//                         <AiOutlineClose className="close-icon" onClick={handleClose} />
//                     </div>
//                     <ul className="sidebar-list">
//                         <li>
//                             <NavLink to={'/'} onClick={handleToggle}>
//                                 Home
//                             </NavLink>
//                         </li>
//                         <li>
//                             <NavLink to={'/works'} onClick={handleToggle}>
//                                 How It Works
//                             </NavLink>
//                         </li>
//                         <li>
//                             <NavLink to={'/blog'} onClick={handleToggle}>
//                                 Blog
//                             </NavLink>
//                         </li>
//                         <li>
//                             <NavLink to={'/contactus'} onClick={handleToggle}>
//                                 Book A Call
//                             </NavLink>
//                         </li>
//                         <li>
//                             <NavLink to={'/logIn'} onClick={handleToggle}>
//                                 Log In
//                             </NavLink>
//                         </li>
//                     </ul>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default CustomNavbar;


